var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _c("ASuccessFour", { attrs: { api: this.api } }),
      _vm.api.isLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _c(
            "v-row",
            { staticClass: "mt-3" },
            [
              _c(
                "v-col",
                {
                  staticClass: "mx-auto",
                  attrs: {
                    align: "center",
                    justify: "center",
                    cols: "12",
                    sm: "12",
                  },
                },
                [
                  _c(
                    "v-card",
                    { staticClass: "pa-3", attrs: { "elevation-1": "" } },
                    [
                      _c(
                        "v-toolbar",
                        { staticClass: "secondary white--text text-h5" },
                        [_vm._v(" Send Email ")]
                      ),
                      _c("div", { staticClass: "mt-4 pt-3 px-3" }, [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-start" },
                          [
                            _c("v-autocomplete", {
                              attrs: {
                                items: _vm.emailOptions,
                                "item-text": "text",
                                "return-object": "",
                                dense: "",
                                outlined: "",
                                label: "Email Template",
                              },
                              model: {
                                value: _vm.selectedEmail,
                                callback: function ($$v) {
                                  _vm.selectedEmail = $$v
                                },
                                expression: "selectedEmail",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "d-flex justify-end" },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "primary", plain: "" },
                                on: {
                                  click: function () {
                                    _vm.$router.go(-1)
                                  },
                                },
                              },
                              [_vm._v(" Cancel ")]
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  loading: _vm.api.isLoading,
                                  color: "primary",
                                },
                                on: {
                                  click: function () {
                                    _vm.isPending = true
                                  },
                                },
                              },
                              [_vm._v(" Confirm ")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }