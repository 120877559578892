var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "px-0 mr-0" },
    [
      _vm.isFirstLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _vm._e(),
      !_vm.isFirstLoading || _vm.data != null
        ? _c("v-data-table", {
            staticClass: "elevation-1 mt-5",
            attrs: {
              items: _vm.filteredCompanies,
              headers: _vm.dataHeaders,
              search: _vm.searchField,
              "item-key": "user_id",
              "show-select": _vm.showSelect,
              "footer-props": {
                showFirstLastPage: true,
              },
            },
            on: { input: _vm.updateUserId },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.Index",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _vm._v(" " + _vm._s(_vm.data.indexOf(item) + 1) + " "),
                    ]
                  },
                },
                {
                  key: "item.name",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "PageCompaniesDetail",
                              params: {
                                id: _vm.data[_vm.data.indexOf(item)]
                                  .companies_id,
                              },
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      ),
                    ]
                  },
                },
                {
                  key: "item.created_at",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      item.created_at != null
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(item.created_at.split(" ")[0]) +
                                " " +
                                _vm._s(
                                  _vm
                                    .convertTimeZone(item.created_at)
                                    .split(" ")[3]
                                ) +
                                " " +
                                _vm._s(
                                  _vm
                                    .convertTimeZone(item.created_at)
                                    .split(" ")[4]
                                ) +
                                " "
                            ),
                          ])
                        : _c("span", [_vm._v(" - ")]),
                    ]
                  },
                },
                {
                  key: "item.welcome_guide_sent",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      item.welcome_guide_sent != null
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(item.welcome_guide_sent.split(" ")[0]) +
                                " " +
                                _vm._s(
                                  _vm
                                    .convertTimeZone(item.welcome_guide_sent)
                                    .split(" ")[3]
                                ) +
                                " " +
                                _vm._s(
                                  _vm
                                    .convertTimeZone(item.welcome_guide_sent)
                                    .split(" ")[4]
                                ) +
                                " "
                            ),
                          ])
                        : _c("span", [_vm._v(" - ")]),
                    ]
                  },
                },
                {
                  key: "item.cs_pic",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      item.cs_id != null
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(item.cs_fname + " " + item.cs_lname) +
                                " "
                            ),
                          ])
                        : _c("span", [_vm._v(" - ")]),
                    ]
                  },
                },
                {
                  key: "top",
                  fn: function () {
                    return [
                      _c(
                        "v-toolbar",
                        {
                          attrs: {
                            width: "auto",
                            height: "auto",
                            color: " py-3",
                            flat: "",
                          },
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "mt-3 mx-3" },
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "mb-3" },
                                    [
                                      _c(
                                        "v-toolbar-title",
                                        {
                                          staticClass:
                                            "font-weight-bold text-h4 my-3",
                                        },
                                        [_vm._v(" Users ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("v-divider"),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { staticClass: "px-0 pb-0" },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _c("v-text-field", {
                                                staticStyle: {
                                                  "border-radius":
                                                    "4px 0px 0px 4px",
                                                },
                                                attrs: {
                                                  "prepend-inner-icon":
                                                    "mdi-calendar",
                                                  label: "Start date",
                                                  dense: "",
                                                  outlined: "",
                                                },
                                                on: {
                                                  "click:prepend-inner":
                                                    function () {
                                                      _vm.isStartDate = true
                                                    },
                                                  click: function () {
                                                    _vm.isStartDate = true
                                                  },
                                                },
                                                model: {
                                                  value: _vm.startDate,
                                                  callback: function ($$v) {
                                                    _vm.startDate = $$v
                                                  },
                                                  expression: "startDate",
                                                },
                                              }),
                                              _c(
                                                "v-dialog",
                                                {
                                                  attrs: {
                                                    height: "auto",
                                                    width: "auto",
                                                  },
                                                  model: {
                                                    value: _vm.isStartDate,
                                                    callback: function ($$v) {
                                                      _vm.isStartDate = $$v
                                                    },
                                                    expression: "isStartDate",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-card",
                                                    { staticClass: "pa-3" },
                                                    [
                                                      _c("p", [
                                                        _vm._v(" Start Date "),
                                                      ]),
                                                      _c("v-date-picker", {
                                                        attrs: {
                                                          flat: "",
                                                          landscape: true,
                                                          reactive: true,
                                                        },
                                                        model: {
                                                          value: _vm.startDate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.startDate = $$v
                                                          },
                                                          expression:
                                                            "startDate",
                                                        },
                                                      }),
                                                      _vm._t(
                                                        "footer",
                                                        function () {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-end white",
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                      plain: "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function () {
                                                                          _vm.isStartDate = false
                                                                          _vm.startDate =
                                                                            null
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Cancel "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function () {
                                                                          _vm.isStartDate = false
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Confirm "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        { staticClass: "px-0 pb-0" },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _c("v-text-field", {
                                                staticStyle: {
                                                  "border-radius": "0px",
                                                },
                                                attrs: {
                                                  "prepend-inner-icon":
                                                    "mdi-calendar",
                                                  label: "End Date",
                                                  dense: "",
                                                  outlined: "",
                                                },
                                                on: {
                                                  "click:prepend-inner":
                                                    function () {
                                                      _vm.isEndDate = true
                                                    },
                                                  click: function () {
                                                    _vm.isEndDate = true
                                                  },
                                                },
                                                model: {
                                                  value: _vm.endDate,
                                                  callback: function ($$v) {
                                                    _vm.endDate = $$v
                                                  },
                                                  expression: "endDate",
                                                },
                                              }),
                                              _c(
                                                "v-dialog",
                                                {
                                                  attrs: {
                                                    height: "auto",
                                                    width: "auto",
                                                  },
                                                  model: {
                                                    value: _vm.isEndDate,
                                                    callback: function ($$v) {
                                                      _vm.isEndDate = $$v
                                                    },
                                                    expression: "isEndDate",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-card",
                                                    { staticClass: "pa-3" },
                                                    [
                                                      _c("p", [
                                                        _vm._v(" End Date "),
                                                      ]),
                                                      _c("v-date-picker", {
                                                        attrs: {
                                                          flat: "",
                                                          landscape: true,
                                                          reactive: true,
                                                        },
                                                        model: {
                                                          value: _vm.endDate,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.endDate = $$v
                                                          },
                                                          expression: "endDate",
                                                        },
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "footer",
                                                          attrs: {
                                                            name: "footer",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex justify-end my-3",
                                                            },
                                                            [
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "primary",
                                                                    plain: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function () {
                                                                        _vm.isEndDate = false
                                                                        _vm.endDate =
                                                                          null
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Cancel "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-btn",
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "primary",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function () {
                                                                        _vm.isEndDate = false
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Confirm "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { staticClass: "px-0" },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              items: _vm.csOptions,
                                              dense: "",
                                              outlined: "",
                                              clearable: "",
                                              label: "Customer service",
                                              "item-text": "username",
                                              "item-value": "id",
                                            },
                                            model: {
                                              value: _vm.selectedCS,
                                              callback: function ($$v) {
                                                _vm.selectedCS = $$v
                                              },
                                              expression: "selectedCS",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-0 mb-4",
                                          attrs: { "align-self": "right" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "d-flex justify-end",
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    plain: "",
                                                  },
                                                  on: {
                                                    click: _vm.resetFilter,
                                                  },
                                                },
                                                [_vm._v(" Reset ")]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: { color: "primary" },
                                                  on: {
                                                    click:
                                                      _vm.filterCompaniesSubscriptionDate,
                                                  },
                                                },
                                                [_vm._v(" Filter ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "mt-3" },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          dense: "",
                                          outlined: "",
                                          clearable: "",
                                          label: "Search",
                                        },
                                        model: {
                                          value: _vm.searchField,
                                          callback: function ($$v) {
                                            _vm.searchField = $$v
                                          },
                                          expression: "searchField",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "black--text" }, [
                                    _vm._v(
                                      " Total users : " +
                                        _vm._s(_vm.totalData) +
                                        " "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
            model: {
              value: _vm.selectedUser,
              callback: function ($$v) {
                _vm.selectedUser = $$v
              },
              expression: "selectedUser",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }